import Vue from 'vue'
import { EagleFormConfigInterface } from '@/components/form/types/form'

class formConfig {
  protected vm?: Vue
  protected formKey?: string

  _defaultData() {
    return {
      name: null,
    }
  }

  get(vm: Vue, formKey: string) : EagleFormConfigInterface {
    this.vm = vm
    this.formKey = formKey

    return {
      // listRoute: () => ({ name: 'provider-update', params: {target: providerId} }),
      pageTitle: (formMeta: any, formData: AnyObject) => window.eagleLodash.get(formData, ['name']),
      pageTitleBase: () => 'module.member',
      defaultData: this._defaultData,
      blocks: [
        {
          title: 'form_block.base',
          data: {
            name: {
              required: true,
              label: 'data.name',
              type: 'text',
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            email: {
              label: 'data.email',
              type: 'text',
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            phone: {
              label: 'data.phone',
              type: 'text',
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
          },
        },

        {
          title: "manage-provider.form_block",
          data: {
            id: {
              component: () => import('@/modules/provider/views/cooperationProviderList.vue'),
              grid: {
                xl: 12,
                lg: 12,
                md: 12,
                sm: 12
              },
            },
          },
        },

        {
          title: "manage-store.form_block",
          data: {
            id: {
              component: () => import('@/modules/store/views/cooperationStoreList.vue'),
              grid: {
                xl: 12,
                lg: 12,
                md: 12,
                sm: 12
              },
            },
          },
        },

        // 其牠資訊
        {
          title: 'form_block.others',
          create: ({ formMode }: FormInfoInterface) => formMode === 'update',
          data: {
            created_at: {
              label: 'data.created_at',
              type: 'time',
              readonly: true,
            },
            updated_at: {
              label: 'data.updated_at',
              type: 'time',
              readonly: true,
            },
          },
        },
      ],
    }
  }

}

export default new formConfig()
