<template>
  <eagle-form :form-key="formKey"></eagle-form>
</template>

<script lang="babel" type="text/babel">
import formMixin from '@/components/form/mixins/formMixin'
import formConfig from './memberFormConfig'
export default {
  mixins: [formMixin],
  data: () => ({
    formKey: 'member-form',
    meta: {},
  }),
  methods: {
    async beforeFormInit() {
      await Promise.all([])
    },
    validateBeforeSave() {
      // TODO
    },
    async readApi(target) {
      const data = await this.$api.collection.memberApi.read(target)
      return data
    },
    async updateApi(target, formData) {
      const data =  await this.$api.collection.memberApi.update(target, formData)
      return data
    },
    // 軟刪除
    async deleteApi(target, formData) {
      return await this.$api.collection.memberApi.delete(formData.pivot_id)
    },
    getFormConfig() {
      return formConfig
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
